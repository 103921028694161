<template>
  <product :title="title"
    :swiper-data="swiperData"
    :slogan="slogan"
    :price="price"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://j.youzan.com/E921Kh"
    is-bus-city
  ></product>
</template>

<script>
import { CDN_URL, RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'stratos-3-lite',
  components: {
    Product
  },
  data () {
    return {
      title: 'Amazfit 智能运动手表 3 精英版',
      slogan: '钛合金表圈 | 蓝宝石屏幕盖板 |  氟橡胶表带 <br/>定制 1.34 英寸全圆反射屏 | 四星三模精准 GPS | 19 种运动模式 <br/> FIRSTBEAT 专业运动分析 | Ultra / 智能双模式 | 最高 14 天长续航 <br/> 70 小时GPS续航 | 百城公交卡 | NFC 模拟门卡（仅支持中国大陆）',
      price: 1699,
      colors: [
        '精英版'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/stratos-3-lite/swiper/5.png',
          CDN_URL + '/images/product/stratos-3-lite/swiper/6.png',
          CDN_URL + '/images/product/stratos-3-lite/swiper/7.png',
          CDN_URL + '/images/product/stratos-3-lite/swiper/8.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        CDN_URL + '/images/product/stratos-3-lite/detail/s01.jpg',
        CDN_URL + '/images/product/stratos-3-lite/detail/s02.jpg',
        CDN_URL + '/images/product/stratos-3-lite/detail/s03.jpg',
        CDN_URL + '/images/product/stratos-3-lite/detail/s04.jpg',
        CDN_URL + '/images/product/stratos-3-lite/detail/s05.jpg',
        CDN_URL + '/images/product/stratos-3-lite/detail/s06.jpg',
        CDN_URL + '/images/product/stratos-3-lite/detail/s07.jpg',
        CDN_URL + '/images/product/stratos-3-lite/detail/s08.jpg',
        CDN_URL + '/images/product/stratos-3-lite/detail/s09.jpg',
        CDN_URL + '/images/product/stratos-3-lite/detail/s10.jpg',
        CDN_URL + '/images/product/stratos-3-lite/detail/s11.jpg',
        CDN_URL + '/images/common/zepp-black.jpg',
        CDN_URL + '/images/product/stratos-3-lite/detail/s12.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/stratos-3-lite/detail/s13.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
